import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer1 = ({handleSelect}) => {
  return (
    <footer className="footer-new">
        <button className='rightseltedbtn btn_i' onClick={handleSelect}>
      Select Footer 1
    </button>
      <Container fluid className="py-5">
        <Row className="justify-content-between">
          {/* Company Info */}
          <Col md={3} sm={12} className="mb-4">
            <h5>Company Name</h5>
            <p>
              We offer innovative tech solutions to empower your business. Get in touch for advanced web, mobile, and cloud services.
            </p>
          </Col>

          {/* Quick Links */}
          <Col md={3} sm={6} className="mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#about">About Us</a></li>
              <li><a href="#services">Our Services</a></li>
              <li><a href="#portfolio">Portfolio</a></li>
              <li><a href="#contact">Contact Us</a></li>
            </ul>
          </Col>

          {/* Contact Info */}
          <Col md={3} sm={6} className="mb-4">
            <h5>Contact Info</h5>
            <p>1234 React Lane, Suite 5678</p>
            <p>City, State, 12345</p>
            <p>Email: info@company.com</p>
            <p>Phone: (123) 456-7890</p>
          </Col>

          {/* Social Media */}
          <Col md={3} sm={12}>
            <h5>Follow Us</h5>
            <div className="social-icons d-flex">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            </div>
          </Col>
        </Row>

        <Row className="text-center pt-3 border-top">
          <Col>
            {/* <p className="mb-0">© {new Date().getFullYear()} Company Name. All rights reserved.</p> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer1;
