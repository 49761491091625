import React from 'react';
import Membership from '../components3/Membership';

function Banner4({ handleSelect }) { // Destructure handleSelect from props
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 4</button>
      <Membership/>
    </div>
  );
}

export default Banner4;
