import React from 'react';
import Latest_News from '../components3/Latest_News';
// import Globle_b from '../components3/Globle_b';

function TabBar4({ handleSelect }) {  // Destructure props to get handleSelect
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 4</button>
      <Latest_News/>
     
    </div>
  );
}

export default TabBar4;




