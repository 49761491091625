import React from 'react';
import Program from '../components3/Programs';

function Banner5({ handleSelect }) { // Destructure handleSelect from props
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 5</button>
      <Program/>
      
   
    </div>
  );
}

export default Banner5;
