import React from 'react'
import limg1 from '../assets/image/team-1.jpg'
import limg2 from '../assets/image/team-2.jpg'
import limg3 from '../assets/image/team-3.jpg'


export default function Latest_News() {
  return (
    <div>
           <section className="news_banner pdb50">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="Featured_banner_box">
                  <div className="news_section_title pdb20">
                    <h2>Latest news &amp; views</h2>
                  </div>
                  <div className="Featured_banner_box_sub_title pdb20">
                    <h4>Uncategorized</h4>
                  </div>
                  <div className="news_section_seond_title">
                    <h3>
                      Mentorship vs. Traditional Learning: Why AI Professionals Need
                      Both
                    </h3>
                  </div>
                  <div className="news Featured_box_text">
                    <p>
                      As Artificial Intelligence (AI) continues to revolutionize
                      industries, the need for highly skilled professionals in this
                      field has never been greater. With the rapid pace of
                      technological advancement, AI professionals...
                    </p>
                    <a className="Read_more" href="#">Read more.</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Featured_banner_box">
                  <div className="Artificial_banner_box_image">
                    <img className="Artificial img-contain" src={limg2} alt="img" />
                  </div>
                  <div className="Featured_banner_box_sub_title pdb20">
                    <h4>Uncategorized</h4>
                  </div>
                  <div className="news_section_seond_title">
                    <h3>
                      Mentorship vs. Traditional Learning: Why AI Professionals Need
                      Both
                    </h3>
                  </div>
                  <div className="news Featured_box_text pb20">
                    <p>
                      As Artificial Intelligence (AI) continues to revolutionize
                      industries, the need for highly skilled professionals in this
                      field has never been greater. With the rapid pace of
                      technological advancement, AI professionals...
                    </p>
                    <a className="Read_more" href="#">Read more.</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Featured_banner_box">
                  <div className="Artificial_banner_box_image">
                    <img className=" Artificial img-contain" src={limg1} alt="img" />
                  </div>
                  <div className="Featured_banner_box_sub_title pdb20">
                    <h4>Uncategorized</h4>
                  </div>
                  <div className="news_section_seond_title">
                    <h3>
                      Mentorship vs. Traditional Learning: Why AI Professionals Need
                      Both
                    </h3>
                  </div>
                  <div className="news Featured_box_text pdb20">
                    <p>
                      As Artificial Intelligence (AI) continues to revolutionize
                      industries, the need for highly skilled professionals in this
                      field has never been greater. With the rapid pace of
                      technological advancement, AI professionals...
                    </p>
                    <a className="Read_more pdb50" href="#">Read more.</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Featured_banner_box">
                  <div className="Artificial_banner_box_image">
                    <img className=" Artificial img-contain" src={limg3} alt="img" />
                  </div>
                  <div className="Featured_banner_box_sub_title pdb20">
                    <h4>Uncategorized</h4>
                  </div>
                  <div className="news_section_seond_title">
                    <h3>
                      Mentorship vs. Traditional Learning: Why AI Professionals Need
                      Both
                    </h3>
                  </div>
                  <div className="news Featured_box_text pdb20">
                    <p>
                      As Artificial Intelligence (AI) continues to revolutionize
                      industries, the need for highly skilled professionals in this
                      field has never been greater. With the rapid pace of
                      technological advancement, AI professionals...
                    </p>
                    <a className="Read_more pdb50" href="#">Read more.</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </div>
  )
}
