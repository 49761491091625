import React, { useState } from 'react';
import '../components4/style4.css'; 
import tabimg_1 from '../assets/image/products/product-1-min.jpg';
import tabimg_2 from '../assets/image/products/product-2-min.jpg';
import tabimg_3 from '../assets/image/products/product-3-min.jpg';
import tabimg_4 from '../assets/image/products/product-5-min.jpg';


function TabBar3({ handleSelect }) {  // Destructure handleSelect from props
  const [activeTab, setActiveTab] = useState('Featured');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section className="Featured_banner mt50">
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>TabBar 3</button>
      <div className="tab">
        <div className="Featured_banner_button pdb20">
          <button
            className={`tablinks Featured btn_i text-center ${activeTab === 'Featured' ? 'active' : ''}`}
            onClick={() => handleTabClick('Featured')}
          >
            Main <span><i className="fa-solid fa-arrow-right" /></span>
          </button>
        </div>
        <div className="Featured_banner_button pdb20">
          <button
            className={`tablinks Featured btn_i text-center ${activeTab === 'Article' ? 'active' : ''}`}
            onClick={() => handleTabClick('Article')}
          >
            Submain <span><i className="fa-solid fa-arrow-right" /></span>
          </button>
        </div>
      </div>
      <div id="Featured" className={`tabcontent ${activeTab === 'Featured' ? 'active' : ''}`}>
        <div className="container">
          <div className="row">
            {/* Your Featured tab content */}
            <div className="col-lg-6">
              <div className="Featured_banner_box">
                <div className="Featured_banner_box_image">
                  <img className="Featured img-contain" src={tabimg_1} alt="img" />
                </div>
                <div className="Featured_banner_box_title">
                  <h3>Community</h3>
                </div>
                <div className="Featured_box_text">
                  <p>
                    Our platform fosters a vibrant community where members can
                    connect, collaborate, and share knowledge. Users can
                    participate in discussions, join interest groups, and network
                    with industry professionals.
                  </p>
                </div>
                <div className="Featured_banner_box_button pdb20">
                  <a href="#" className="Membership btn_i text-center">
                    Let Start
                    <span><i className="fa-solid fa-arrow-right" /></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Featured_banner_box">
                <div className="Featured_banner_box_image">
                  <img className="Featured img-contain" src={tabimg_3} alt="img" />
                </div>
                <div className="Featured_banner_box_title">
                  <h3>Community</h3>
                </div>
                <div className="Featured_box_text">
                  <p>
                    Our platform fosters a vibrant community where members can
                    connect, collaborate, and share knowledge. Users can
                    participate in discussions, join interest groups, and network
                    with industry professionals.
                  </p>
                </div>
                <div className="Featured_banner_box_button pdb20">
                  <a href="#" className="Membership btn_i text-center">
                    Let Start
                    <span><i className="fa-solid fa-arrow-right" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Article" className={`tabcontent ${activeTab === 'Article' ? 'active' : ''}`}>
        <div className="container">
          <div className="row">
            {/* Your Article tab content */}
            <div className="col-lg-6">
              <div className="Featured_banner_box">
                <div className="Featured_banner_box_image">
                  <img className="Featured img-contain" src={tabimg_2} alt="img" />
                </div>
                <div className="Featured_banner_box_title">
                  <h3>Community</h3>
                </div>
                <div className="Featured_box_text">
                  <p>
                    Our platform fosters a vibrant community where members can
                    connect, collaborate, and share knowledge. Users can
                    participate in discussions, join interest groups, and network
                    with industry professionals.
                  </p>
                </div>
                <div className="Featured_banner_box_button pdb20">
                  <a href="#" className="Membership btn_i text-center">
                    Let Start
                    <span><i className="fa-solid fa-arrow-right" /></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Featured_banner_box">
                <div className="Featured_banner_box_image">
                  <img className="Featured img-contain" src={tabimg_4} alt="img" />
                </div>
                <div className="Featured_banner_box_title">
                  <h3>Community</h3>
                </div>
                <div className="Featured_box_text">
                  <p>
                    Our platform fosters a vibrant community where members can
                    connect, collaborate, and share knowledge. Users can
                    participate in discussions, join interest groups, and network
                    with industry professionals.
                  </p>
                </div>
                <div className="Featured_banner_box_button pdb20">
                  <a href="#" className="Membership btn_i text-center">
                    Let Start
                    <span><i className="fa-solid fa-arrow-right" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TabBar3;
