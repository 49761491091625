import React from 'react';
import Globle_b from '../components3/Globle_b';

function Banner3({ handleSelect }) {
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>
        Select Banner 3
      </button>
      <Globle_b />
    </div>
  );
}

export default Banner3;
