import React from 'react'

export default function Programs() {
  return (
    <div>
         <section className="Find_out_more_about mt20">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="find_out_more_about_title">
                  <h2>Find out more about who we are and what we do.</h2>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="slider_item_button text-right  pdb20">
                  <a href="#" className="btn_i ">ABOUT GIOFAI <i className="fa-solid fa-arrow-right" /></a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="main_article d-flex gap20 pdb50 flexwrap" style={{justifyContent: 'center'}}>
                <div className="article_box">
                  <div className="about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-graduation-cap" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>TRAINING PROGRAMS</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        We guide innovation, fostering growth, and shape with Training
                        Programs
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-graduation-cap" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>FREE WEBINARS</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Join our insightful webinar for expert insights on
                        cutting-edge industry trends.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-video" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>NEWS &amp; MEDIA</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Read up on the latest news and views on the issues that matter
                        to AI Community.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-regular fa-images" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>EVENTS</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Hear from inspiring speakers, connect with your peers and
                        discuss the latest ideas.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-microphone" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>COMMUNITY</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Collaborate &amp; exchange knowledge with AI enthusiasts to shape
                        the future together.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-regular fa-comment-dots" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>MENTORSHIP PROGRAMS</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>Develop your Skills With Mentorship Programs Develop your Skills With Mentorship Programs</p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </div>
  )
}
