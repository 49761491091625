import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/image/logo.png';

function Header5({ handleSelect }) {
  return (
   <div className='fiveHeader'>
     <Navbar collapseOnSelect expand="lg"  sticky="top">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} style={{ width: '50%' }} alt="Company Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about-us">About Us</Nav.Link>
            <NavDropdown title="Blogs" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#blogs">Blogs</NavDropdown.Item>
              <NavDropdown.Item href="#contact">Contact</NavDropdown.Item>
              <NavDropdown.Item href="#blog1">Blog 1</NavDropdown.Item>
              <NavDropdown.Item href="#something">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#separated-link">Separated link</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <div className="sign-up">
            <button onClick={handleSelect} className="sign-up btn_i">
              Choose
            </button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>

   </div>
  );
}

export default Header5;
