import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import logo from '../assets//image/logo.png';

 function Footer4({handleSelect}) {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 4</button>
  
        <div className='me-5 d-none d-lg-block'>
        <a href="">
            <img src={logo} style={{ width: '50%' }} alt="Logo" />
          </a>
        </div>

     
        <br/>
        <div className='footer_navbar'>
             
              <p>
                <a href='#!' className='text-reset'>
                  Home
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  About
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Blogs
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Contact
                </a>
              </p>
              
            </div>
            <div className='Footersocial_icon'>
          <a href='' className='me-4 text-reset'>
          <FaSquareFacebook />
          </a>
          <a href='' className='me-4 text-reset'>
            <FaWhatsappSquare/>
          </a>
          <a href='' className='me-4 text-reset'>
            <FaSquareInstagram/>
          </a>
          <a href='' className='me-4 text-reset'>
        <FaSquareXTwitter/>
          </a>
          <a href='' className='me-4 text-reset'>
         <FaGoogle/>
          </a>
          <a href='' className='me-4 text-reset'>
            <FaLinkedin/>
         
          </a>
          <a href='' className='me-4 text-reset'>
            <FaGithub/>
         
          </a>
        </div>
      </section>

    </MDBFooter>
  );
}

export default Footer4;