import React from 'react';

// import Highlight from './TabBar1';
// import ClientTesimonial from './ClientTesimonial';
// import ContactUs from './ContactUs';
// import Footer from './Footer';
import PropTypes from 'prop-types';
// import Globle_b from '../components3/Globle_b';

function AboutPage1({ handleSelect }) {
  return (
    <div>
      
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Select AboutPage1</button>

      <section className='about-coaching pdb50 animate-up'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='main-about-title text-center pdb50 '>
                <h1>Coaching and Advising for <br/> Growth Teams</h1>
              </div>
              <div className='main-about-text text-center'>
                <h4>Learn factors, playbooks, and leadership strategies to deliver <br/> more value and grow your career in tech.</h4>
              </div>
              <div className='about-submit-button text-center pdb50'>
                <button type='button' className='btn btn-danger'>Learn More</button>
              </div>
              <div className='about-url text-center'>
                <a href='#'>Or subscribe to our newsletter for updates</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Globle_b/> */}
      {/* <Highlight/> */}
      {/* <ClientTesimonial/>
      <ContactUs/>
      <Footer/> */}
    </div>
  );
}

AboutPage1.propTypes = {
  handleSelect: PropTypes.func.isRequired,
};

export default AboutPage1;
