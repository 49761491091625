import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/image/logo.png';

function Header4({ handleSelect }) {
  return (
   <div className='headerFour'>

      {/* <Navbar collapseOnSelect expand="lg" className="" sticky="top">
      <Container>
       
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about-us">About Us</Nav.Link>
            <NavDropdown title="Blogs" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#blogs">Blogs</NavDropdown.Item>
              <NavDropdown.Item href="#contact">Contact</NavDropdown.Item>
              <Navbar.Brand href="">
          <img src={logo} style={{ width: '50%' }} alt="Logo" />
        </Navbar.Brand>
              <NavDropdown.Item href="#blog1">Blog 1</NavDropdown.Item>
              <NavDropdown.Item href="#something">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#separated-link">Separated link</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <button onClick={handleSelect} className="sign-up btn_i">
            Choose
          </button>
        </Navbar.Collapse>
      </Container>
    </Navbar> */}
    <nav className="navbar navbar-expand-md navbar-dark bg-secondary">
  <div className="container-fluid">
    <a className="navbar-brand d-md-none" href="#">
      <img src={logo} alt=""/>
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav mx-auto">
        <li className="nav-item">
          <a className="nav-link " aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Features</a>
        </li>
        <a className="navbar-brand d-none d-md-block" href="#">
          <img src={logo} alt=""/>
        </a>
        <li className="nav-item">
          <a className="nav-link" href="#">Pricing</a>
        </li>
        <li className="nav-item">
          <a className="nav-link">Contact</a>
        </li>
      </ul>
      <button onClick={handleSelect} className="sign-up btn_i">
            Choose
          </button>
    </div>
  </div>
</nav>



   </div>
  );
}

export default Header4;
