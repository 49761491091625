// import React from 'react';
// import   './style5.css';
// import bgimage from '../assets/image/bg-presentation.jpg';





// // 
// function AboutPage5({  handleSelect }) {
//   return (
//     <>
//     <div>
//     <div className="header_button_m">
//               <a
//                 href="javascript:void(0)"
//                 className="btn bttn_login"
//                 onClick={handleSelect}
//                 aria-label="login"
//               >
//                 Login
//               </a>
//             </div>

//     <>

  // <section
  //   className="hero_banner_m d-flex align-items-center"
  //   style={{ backgroundImage: URL({bgimage}) }}
  // >
  //   <div className="container">
  //     <div className="row">
  //       <div className="col-lg-12">
  //         <div className="hero_banner_i">
  //           <div className="banner_logo_m text-center">
  //             <img
  //               src="./assets/image/banner-logo.png"
  //               alt="banner logo"
  //               className="img-contain"
  //             />
  //           </div>
  //           <div className="banner_heading-m text-center">
  //             <h1>Ensuring Quality Cow-Horse genetics</h1>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //   <div className="bottom_button_m">
  //     <a
  //       href="#catslider"
  //       className="bttn_i bttn_arrow"
  //       aria-label="button down"
  //     >
  //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  //         <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
  //       </svg>
  //     </a>
  //   </div>
  // </section>
  // {/* banner section end*/}

//   {/* Mares section start */}
//   {/* <section className="progeny_main_m" id="">
//     <div className="container-fluid no-padding hidden">
//       <div className="row">
//         <div className="col-lg-12">
//           <div className="pogeny_info_inner_e d-flex">
//             <div className="pogeny_info_inner d-flex justify-content-center">
//               <div className="pogeny_sub_heading">
//                 <h5>Performance and Progeny</h5>
//               </div>
//               <div className="pogeny_heading mb20">
//                 <h2>
//                   Mares : the <br />
//                   cornerstone of our
//                   <br />
//                   future genetics.
//                 </h2>
//               </div>
//               <div className="progeny_para">
//                 <p>
//                   "In this day and age, I believe a proven mare in any chosen
//                   discipline that is backed by good genetics is as equally
//                   important as the choice of stallion you are breeding to. A
//                   proven mare will add value to your foal from the very get go"
//                 </p>
//                 <p>– Jaye Hall</p>
//               </div>
//               <div className="progeny_list mb20">
//                 <ul className="list-none">
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Australia’s Leading Genetics.
//                   </li>
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     100% Performers.
//                   </li>
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Producing The Proof in Performing Progeny.
//                   </li>
//                 </ul>
//               </div>
//               <div className="progeny_bttn">
//                 <a href="javascript:void(0)" className="btn_i">
//                   View Mares
//                 </a>
//               </div>
//             </div>
//             <div className="progeny_img">
//               <img
//                 src="./assets/image/Rectangle 12.png"
//                 alt="progeny_img"
//                 className="img-cover"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section> */}
//   {/* Mares section end */}
//   {/* Stallions section start */}
//   {/* <section className="progeny_main_m Stallions_section">
//     <div className="container-fluid no-padding hidden">
//       <div className="row">
//         <div className="col-lg-12">
//           <div className="pogeny_info_inner_e d-flex">
//             <div className="progeny_img">
//               <img
//                 src="./assets/image/hourse.png"
//                 alt="progeny_img"
//                 className="img-cover"
//               />
//             </div>
//             <div className="pogeny_info_inner d-flex justify-content-center">
//               <div className="pogeny_sub_heading">
//                 <h5>The Topside</h5>
//               </div>
//               <div className="pogeny_heading mb20">
//                 <h2>
//                   Stallions : <br />
//                   The Sire Producing <br />
//                   Quality Genetics and Performers
//                 </h2>
//               </div>
//               <div className="progeny_para">
//                 <p>
//                   Want to inspect every detail about the Stallion you’re
//                   breeding to ? Have questions about the sire of your future
//                   progeny? Eminent Equine can provide the answers for all your
//                   inquiries.
//                 </p>
//               </div>
//               <div className="progeny_list mb20">
//                 <ul className="list-none">
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Helping Australians Breed the best
//                   </li>
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Improving Future Genetics
//                   </li>
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Providing everything you need to know about the ‘Top Side’
//                   </li>
//                 </ul>
//               </div>
//               <div className="progeny_bttn">
//                 <a href="javascript:void(0)" className="btn_i">
//                   View Stallions
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section> */}
//   {/* Mares section end */}


//   {/* pricing plan start */}

//   {/* pricing plan end */}
//   {/* who are section start */}
//  <section className="who_are_m pdb100">
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-12">
//           <div className="who_inner_e d-flex gap20">
//             <div className="who_info d-flex justify-content-center">
//               <div className="who_sub_heading">
//                 <h5>Who We Are</h5>
//               </div>
//               <div className="who_heading mb20">
//                 <h2>Best Equestrian And Horse Cutting In Town</h2>
//               </div>
//               <div className="who_para">
//                 <p>
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                   Praesent rutrum nunc sit amet iaculis euismod. Pellentesque
//                   vestibulum viverra sapien eget mattis. Nulla lobortis. Nullam
//                   laoreet condimentum purus sit amet suscipit. Ut eu neque
//                   tincidunt.
//                 </p>
//                 <p>
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                   Praesent rutrum nunc sit amet iaculis euismod. Pellentesque
//                   vestibulum viverra sapien eget mattis. Nulla lobortis.
//                 </p>
//               </div>
//               <div className="who_list mb20">
//                 <ul className="list-none">
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     100% Guarantee
//                   </li>
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Best Horse Riding
//                   </li>
//                   <li>
//                     <span className="check_listing">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
//                       </svg>
//                     </span>
//                     Commitment to Customers
//                   </li>
//                 </ul>
//               </div>
//               <div className="who_bttn">
//                 <a href="javascript:void(0)" className="btn_i">
//                   About Us
//                 </a>
//               </div>
//             </div>
//             <div className="who_img">
//               <img
//                 src="./assets/image/NB-0171 1.png"
//                 alt="who_img"
//                 className="img-contain"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section> */}


import React from 'react';

import Slide5 from './Slide5';

function AboutPage5({ handleSelect }) {
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 5</button>
      <Slide5/>

    </div>
  );
}

export default AboutPage5;
