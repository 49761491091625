import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import officeImage from '../assets/image/contact.png'; // Replace with your image path

const ContactPage = ({handleSelect}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Thank you ${formData.name}, your message has been sent!`);
  };

  return (
    <Container fluid className="contact-section mb50">
       <button className='rightseltedbtn btn_i' onClick={handleSelect}>
      Select Footer 4
    </button>
      <Row className="d-flex align-items-center justify-content-center">
        
        {/* Office Location Section */}
        <Col md={6} className="p-5 location-section">
          <div className="text-center mb-4">
            <img src={officeImage} alt="Office Location" className="img-fluid mb-3" />
            {/* <h3>Our Office</h3>
            <p>1234 React Lane, Suite 5678</p>
            <p>City, State, 12345</p>
            <p>Email: contact@company.com</p>
            <p>Phone: (123) 456-7890</p> */}
          </div>
        </Col>

        {/* Contact Form Section */}
        <Col md={6} sm={12} className="p-5">
          <h2 className="text-center mb-4">Contact Us</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="message" className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={5}
                placeholder="Write your message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Send Message
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
