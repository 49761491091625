import React from 'react';
import contact from '../assets/image/telecommuting.png';

function Contact1({ handleSelect }) { // Destructure handleSelect from props
  const myimage = {
    width: '100%',
    height: '100%',
  };

  return (
    <section id="get_touch_m">
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 1</button>
      <div className="container-fluid no-padding hidden">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="get_touch_bg d-flex justify-content-center"
              style={{
                backgroundImage: ``,
              }}
            >
              <div className="get_touch_logo">
                <img
                  src={contact}
                  alt="get in touch logo"
                  className="img-contain"
                  style={myimage}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="get_touch_form_m">
              <div className="get_touch_sub">
                <h5>Contact Us</h5>
              </div>
              <div className="get_touch_heading mb20">
                <h3>Get In Touch</h3>
              </div>
              <div className="forms_main">
                <form action="#">
                  <div className="main_groups d-flex gap20">
                    <div className="group_fields">
                      <label htmlFor="fname">
                        <span className="visually-hidden">First name:</span>
                      </label>
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="group_fields">
                      <label htmlFor="phone">
                        <span className="visually-hidden">Your Phone</span>
                      </label>
                      <input
                        type="phone"
                        id="phone"
                        name="phone"
                        placeholder="Your Phone"
                      />
                    </div>
                  </div>
                  <div className="main_groups">
                    <div className="group_fields">
                      <label htmlFor="email">
                        <span className="visually-hidden">Your Email</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="main_groups">
                    <div className="group_fields">
                      <label htmlFor="w3review">
                        <span className="visually-hidden">Enter Your Message</span>
                      </label>
                      <textarea
                        id="w3review"
                        name="w3review"
                        rows="4"
                        cols="48"
                        placeholder="Enter Your Message"
                      />
                    </div>
                  </div>
                  <div className="main_groups">
                    <input
                      type="submit"
                      value="Submit"
                      className="submit_bttn btn_i"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact1;
