import React, { useState, useEffect } from 'react';
import bgimg1 from '../assets/image/examples/blog-9-4.jpg';
import bgimg2 from '../assets/image/examples/testimonial-6-3.jpg';
import bgimg3 from '../assets/image/examples/testimonial-6-2.jpg';

const Slide5 = () => {
  const slides = [
    {
      backgroundImage: bgimg1,
      title: 'Are you equipped with?',
      description: 'Elevate your career trajectory with our Career Transition Mentorship Programs or Launch your career with confidence.',
      buttonText: 'Learn More',
      buttonLink: '#'
    },
    {
      backgroundImage: bgimg2,
      title: 'Are you equipped with Data Skills?',
      description: 'Elevate your career trajectory with our Launch your career with confidence through our Career Starter Training Programs.',
      buttonText: 'Learn More',
      buttonLink: '#'
    },
    {
      backgroundImage: bgimg3,
      title: 'Are you equipped with Data  Career?',
      description: 'Elevate your career trajectory with our Career Transition Mentorship Programs or Launch your career with confidence through our Career Starter Training Programs.',
      buttonText: 'Learn More',
      buttonLink: '#'
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slides.length;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      changeSlide(1);
    }, 5000); // Auto-slide every 5 seconds

    return () => clearInterval(slideInterval); // Cleanup on unmount
  }, [currentSlide]);

  const changeSlide = (n) => {
    const newIndex = (currentSlide + n + totalSlides) % totalSlides;
    setCurrentSlide(newIndex);
  };

  return (
    <section className="equipped_data_AI_main mb50">
      <div className="image_slide">
        <div className="custom-carousel">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slider_items ${currentSlide === index ? 'active' : ''}`}
              style={{ backgroundImage: `url(${slide.backgroundImage})` }}
            >
              <div className="slide_inner">
                <div className="slider_item_title text-center mb20">
                  <h2>{slide.title}</h2>
                </div>
                <div className="slider_item_para text-center mb20">
                  <p>{slide.description}</p>
                </div>
                <div className="slider_item_button text-center">
                  <a href={slide.buttonLink} className="slide_btn btn_i">
                    {slide.buttonText} <i className="fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="controls">
          <span className="prev" onClick={() => changeSlide(-1)}>❮</span>
          <span className="next" onClick={() => changeSlide(1)}>❯</span>
        </div>
      </div>
    </section>
  );
};

export default Slide5;
