import React from 'react'
import aboutImg from '../assets/image/team-5.jpg'

export default function Globle_b() {
  return (
    <div>
         <section className="Global_Institute pdb50 mb50">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="Global_Institute_text">
                  <div className="Global_Institute_title mb20">
                    <h2> Discover Institute of Artificial Intelligence</h2>
                  </div>
                  <div className="globle_text">
                    <p>
                      Discover the forefront of innovation at the Global Institute
                      of Artificial Intelligence (GIofAI). Our mission is to propel
                      society forward through the exploration and implementation of
                      cutting-edge AI technologies. Dive into a vibrant AI &amp; Data
                      Community, engage in dynamic AI &amp; Data Groups, and expand your
                      expertise with our comprehensive AI &amp; Data courses. Access a
                      wealth of AI &amp; Data tools and resources to empower your
                      projects and initiatives. Join us for enlightening AI &amp; Data
                      Events and meetups, and stay updated with the latest insights
                      at our AI &amp; Data Conference. Explore captivating content
                      through our AI &amp; Data Blog, podcast, and interviews, and
                      unlock endless possibilities with GIofAI.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Global_Institute_image mb20">
                  <img src={aboutImg} className='img-contain' alt='img'></img>
                </div>
                <div className="d-flex align-items-center justify-space-between main_btn_membership">
                  <div className="Global_Institute_button pdb20">
                    <h3>Free Membership</h3>
                  </div>
                  <div className="globls_inst_button text-left">
                    <a href="#" className="Free btn_i">Sign Up <i className="fa-solid fa-arrow-up" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </div>
  )
}
