import React from 'react';
import TouchPage from './TouchPage';

function Contact5({ handleSelect }) {
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>
      Choose 5
      </button>
      <TouchPage/>
    </div>
  );
}

export default Contact5;
