import React from 'react';
import '../components4/style4.css'; // Ensure you have the correct path for your styles
import PropTypes from 'prop-types';
import { GrBeacon, GrBraille, GrCloudlinux } from "react-icons/gr";
import { GiTwirlyFlower } from "react-icons/gi";
// import BannerSection from '../components4/BannerSection';
// import AccordionSection from '../components4/AccordionSection';
// import ServiceSection from '../components4/ServiceSection';
// import Subcription from '../components4/Subcription';
// import FooterSection from '../components4/FooterSection';
import { Button } from 'react-bootstrap';

function AboutPage4({ title, text1, text2, text3, handleMainButtonClick, handleSelect }) {
  return (
    <>
      <div>
       
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 4</button>
      </div>
      <section className='main_about_page'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="About_setion_text">
                <h2>{title}</h2>
              </div>
              <div className="about_section_Intrucion">
                <p>{text1}</p>
                <p>{text2}</p>
                <p>{text3}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="About_setion_text_icon">
                <div className='icon_text'>
                  <div className='icon_text1'><GrBeacon /> <h3>Hit specific earnings goals</h3></div>
                  <br/>
                  <div className='icon_text1'><GrBraille /> <h3>Build a team and delegate</h3></div>
                  <br/>
                  <div className='icon_text1'><GrCloudlinux /> <h3>Create processes and systems</h3></div> 
                  <br/>
                  <div className='icon_text1'><GiTwirlyFlower /> <h3>Set "finish lines" and enjoy home life</h3></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* 
      <BannerSection /> */}
      {/* <AccordionSection />
      <ServiceSection />
      <Subcription />
      <FooterSection /> */}
    </>
  );
}

AboutPage4.defaultProps = {
  title: 'Become The Most Effective Entrepreneur In Your Circle',
  text1: 'Getting clarity on your most important work and applying the science of productivity to your plans each week gives you leverage, and produces compounding results.',
  text2: 'You start consistently working ON your business, not just IN it. And hitting your biggest goals, without clocking more hours at your desk, or sacrificing your freedom and control.',
  text3: 'We have helped thousands of entrepreneurs achieve all kinds of incredible life-changing goals with our accountability coaching program.',
};

AboutPage4.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,  
  text2: PropTypes.string,
  text3: PropTypes.string,
  handleMainButtonClick: PropTypes.func.isRequired, // Updated prop name
  handleSelect: PropTypes.func.isRequired, // Updated prop name
};

export default AboutPage4;
