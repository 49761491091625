import React from 'react';
import { Button } from 'react-bootstrap';
import   './style3.css';
import Slideimage from './Slideimage';

// import Globle_b from './Globle_b';
// import Programs from './Programs';
// import Membership from './Membership';
// import Traning from './Traning';
// import Tabs from './TabPage';
// import Latest_News from './Latest_News';
// import FooterSet3 from './Footer3';

function AboutPage3({ handleSelect }) {
  return (
    <div>
   
   <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 2</button>

      
 
        {/* slider start */}
       <Slideimage/>
        {/* slider end*/}
       
       {/* <Globle_b/> */}
        {/* video text info end */}
        {/* icon box start */}
{/*        
       <Programs/> */}
        {/* icon box end */}
        {/* membershiip start */}
      {/* <Membership/> */}
        {/* MEMBERSHIP end */}
       
        {/*TRAINING start  */}
       {/* <Traning/> */}
        {/* TRAINING & MENTORSHIP end */}
      {/* <Tabs/> */}
      
        {/* tab end */}
        {/* Featured Section end */}
        {/*  */}
     {/* <Latest_News/> */}
        {/* news end     */}
        {/* footer start*/}
      {/* <FooterSet3/> */}
        {/* footer end */}
        {/*  */}
        {/* tab */}
      </div>
  
  
  );
}

export default AboutPage3;


// import React from 'react';

// function AboutPage3({ handleSelect }) {
//   return (
//     <div>
//       <h1>AboutPage3 Component</h1>
//       <button onClick={handleSelect}>Select AboutPage3</button>
//     </div>
//   );
// }

// export default AboutPage3;
