import React from 'react'

export default function TouchPage() {
  return (
    <div>
         <section id="get_touch_m">
    <div className="container-fluid no-padding hidden">
      <div className="row">
        <div className="col-lg-6">
          <div
            className="get_touch_bg d-flex justify-content-center"
            style={{
              backgroundImage: 'url("./assets/image/Rectangle 21.png")'
            }}
          >
            <div className="get_touch_logo">
              <img
                src="./assets/image/2__2_-removebg-preview (1).png"
                alt="get in touch logo"
                className="img-contain"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center">
          <div className="get_touch_form_m">
            <div className="get_touch_sub">
              <h5>Contact Us</h5>
            </div>
            <div className="get_touch_heading mb20">
              <h3>Get In Touch</h3>
            </div>
            <div className="forms_main">
              <form action="#">
                <div className="main_groups d-flex gap20">
                  <div className="group_fields">
                    <label htmlFor="fname">
                      <span className="visually-hidden">First name:</span>
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="group_fields">
                    <label htmlFor="Yphone">
                      <span className="visually-hidden">Your Phone</span>
                    </label>
                    <input
                      type="phone"
                      id="phone"
                      name="phone"
                      placeholder="Your Phone"
                    />
                  </div>
                </div>
                <div className="main_groups">
                  <div className="group_fields">
                    <label htmlFor="Youremail">
                      <span className="visually-hidden">Your Email</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="main_groups">
                  <div className="group_fields">
                    <label htmlFor="textarea">
                      <span className="visually-hidden">
                        Enter Your Message
                      </span>
                    </label>
                    <textarea
                      id="w3review"
                      name="w3review"
                      rows={4}
                      cols={48}
                      placeholder="Enter Your Message"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="main_groups">
                  <input
                    type="submit"
                    defaultValue="Submit"
                    className="submit_bttn btn_i"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
      
    </div>
  )
}
