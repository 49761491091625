import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import bg2 from '../assets/image/bg-presentation.jpg';
import womanImageSrc from '../assets/image/team-1.jpg'; // Replace with your actual image path
import './style2.css';
// import Banner from './Banner';
// import Programs from '../components3/Programs';
// import Latest_News from '../components3/Latest_News';
// import Footer from '../components5/Footer';

function AboutPage2({ title, text, handleMainButtonClick, handleSelect }) {
  const womanImageStyle = {
    width: '80%',
    padding: '38px 18px',
  };

  return (
    <div>
       <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 2</button>
      <section
        className='main_banner_page2 animate-up'
        style={{ backgroundImage: `url(${bg2})` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='main_bannerItext'>
                <div className='main_banner_image_title '>
                  <h2>{title}</h2>
                </div>
                <div className='main_banner_text'>
                  <p>{text}</p>
                  <br />
                </div>
                <Button variant="outline-primary" onClick={handleMainButtonClick}>
                  Learn More
                </Button>{' '}
                
              </div>
            </div>
           
          </div>
        </div>
      </section>
      {/* <Banner /> */}
      {/* <Programs/>
      <Latest_News/>
      <Footer/> */}
    </div>
  );
}

AboutPage2.defaultProps = {
  title: 'Tackle projects with more ease and faster execution',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
};

AboutPage2.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  handleMainButtonClick: PropTypes.func,
  handleSelect: PropTypes.func,
};

export default AboutPage2;
