// Banner2.js
import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineArrowCircleRight } from "react-icons/md";
import bimg2 from '../assets/image/woman.jpg';

function Banner1({ title, text, handleSelect }) {
  const imageStyle = {
    width: "100%",
    
    
  };

  return (
    <div>
      <section className='About_BannerPage_main'>
        <button className='rightseltedbtn btn_i' onClick={handleSelect}>Select Banner</button>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className="about_banner2PageText">
                <h2 id='title'>{title}</h2>
                <p>{text}</p>
                <div className='bannerButtton'>
                  <button type='button' className='btn btn-success'>
                    Subscribers <MdOutlineArrowCircleRight />
                  </button>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="about_bannerPageImg">
                {/* Uncomment and adjust the image import and rendering as needed */}
                <img src={bimg2} style={imageStyle} alt="Banner"  className='img-contain'/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Banner1.defaultProps = {
  title: 'You don’t have to run your business like everyone else.',
  text: 'Join 8,181+ subscribers who are challenging conventional wisdom to increase their income, free up their days, and change their corner of the world for the better.',
};

Banner1.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
};

export default Banner1;
