import React, { useState } from 'react';
import { Button, Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'; // Import social media icons
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/image/logo.png';
// import ToggleSwitch from './ToggleSwitch';  // Import ToggleSwitch component

const Header = ({ handleSelect }) => {
  // const [isSelected, setIsSelected] = useState(false);
  // const [toggleChecked, setToggleChecked] = useState(false);  // Toggle state for the switch

  // const handleToggle = () => {
  //   setIsSelected(!isSelected);  // Toggle the button's state
  //   handleSelect();  // Call the passed handleSelect function
  // };

  return (
    <div className="header">
      {/* Top section for email and social media icons */}
      <div className="top-header py-2">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Left: Email */}
          <div>
            <a href="mailto:info@example.com" className="navbar-email">
              info@example.com
            </a>
          </div>

          {/* Right: Social Media Icons */}
          <div className="social-icons d-flex align-items-center">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaFacebook />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaTwitter />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaInstagram />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaLinkedin />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>

      {/* Sticky Navbar Section */}
      <div className='hederOne'>
        <BootstrapNavbar expand="lg" className="sticky-top bg-white">
          <div className="container">
            <BootstrapNavbar.Brand href="#">
              <img src={logo} alt="Logo" className="navbar-logo" />
            </BootstrapNavbar.Brand>
            <BootstrapNavbar.Toggle aria-controls="navbarNav" />
            <BootstrapNavbar.Collapse id="navbarNav">
              <Nav className="ms-auto">
                <Nav.Link href="#">Home</Nav.Link>
                <Nav.Link href="#">About-us</Nav.Link>
                <Nav.Link href="#">Contact</Nav.Link>
                <Nav.Link href="#">Blogs</Nav.Link>

                {/* Toggle button */}
                <Button onClick={handleSelect} className="sign-up btn_i">
              Choose
            </Button>

                {/* Add ToggleSwitch component */}
              
              </Nav>
            </BootstrapNavbar.Collapse>
          </div>
        </BootstrapNavbar>
      </div>
    </div>
  );
};

export default Header;
