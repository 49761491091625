// import './App.css';
// import '../src/Main.css';
// import { useState, useEffect } from 'react';
// import { Button, Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// // Header page import
// import Header from './components1/Header';
// import Header2 from './components2/Header2';
// import Header3 from './components3/Header3';
// import Header4 from './components4/Header4';
// import Header5 from './components5/Header5';
// // AboutPage page import
// import AboutPage1 from './components1/AboutPage1';
// import AboutPage2 from './components2/AboutPage2';
// import AboutPage3 from './components3/AboutPage3';
// import AboutPage4 from './components4/AboutPage4';
// import AboutPage5 from './components5/AboutPage5';
// // TabBar page import
// import TabBar1 from './components1/TabBar1';
// import TabBar2 from './components2/TabBar2';
// import TabBar3 from './components3/TabBar3';
// import TabBar4 from './components4/TabBar4';
// import TabBar5 from './components5/TabBar5';
// // Banner Page import


// import Banner1 from './components1/Banner1';
// import Banner2 from './components2/Banner2';
// import Banner3 from './components3/Banner3';
// import Banner4 from './components4/Banner4';
// import Banner5 from './components5/Banner5';


// // Testimonial Page import
// // import Testimonial1 from './components1/Testimonial1';
// // import Tesimonial2 from './components2/Testimonial2';

// // Contact Page import

// import Contact1 from './components1/Contact1';
// import Contact2 from './components2/Contact2';
// import Contact3 from './components3/Contact3';
// import Contact4 from './components4/Contact4';
// import Contact5 from './components5/Contact5';


// // Footer page import

// import Footer1 from './components1/Footer1';
// import Footer2 from './components2/Footer2';
// import Footer3 from './components3/Footer3';
// import Footer4 from './components4/Footer4';
// import Footer5 from './components5/Footer5';




// function App() {
//   const [selectedComponent, setSelectedComponent] = useState(localStorage.getItem('selectedComponent') || 'Header');
//   const [selectedAboutComponent, setSelectedAboutComponent] = useState(localStorage.getItem('selectedAboutComponent') || 'AboutPage1');
//   const [selectedTabComponent, setSelectedTabComponent] = useState(localStorage.getItem('selectedTabComponent') || 'TabBar1');
//   const [selectedBannerComponent, setSelectedBannerComponent] = useState(localStorage.getItem('selectedBannerComponent') || 'Banner1');
//   // const [selectedTesimonialComponent, setSelectedTesimonialComponent] = useState(localStorage.getItem('selectedTesimonialComponent') || 'Tesimonial1');
//   const [selectedContactComponent, setSelectedContactComponent] = useState(localStorage.getItem('selectedContactComponent') || 'Contact1');
//   const [selectedFooterComponent, setSelectedFooterComponent] = useState(localStorage.getItem('selectedFooterComponent') || 'Footer1');
//   const [showHeadersModal, setShowHeadersModal] = useState(false);
//   const [showAboutPagesModal, setShowAboutPagesModal] = useState(false);
//   const [showTabPageModel, setShowTabPageModel] = useState(false);
//   const [showBannerPageModel, setShowBannerPageModel] = useState(false);
//   // const [showTesimonialPageModel, setShowTesimonialPageModel] = useState(false);
//   const [showContactPageModel, setShowContactPageModel] = useState(false);
//   const [showFooterPageModel, setShowFooterPageModel] = useState(false);


  

  

//   useEffect(() => {
//     localStorage.setItem('selectedComponent', selectedComponent);
//   }, [selectedComponent]);

//   useEffect(() => {
//     localStorage.setItem('selectedAboutComponent', selectedAboutComponent);
//   }, [selectedAboutComponent]);

//   useEffect(() => {
//     localStorage.setItem('selectedTabComponent', selectedTabComponent);
//   }, [selectedTabComponent]);

//   useEffect(() => {
//     localStorage.setItem('selectedBannerComponent', selectedBannerComponent);
//   }, [selectedBannerComponent]);

//   // useEffect(() => {
//   //   localStorage.setItem('selectedTesimonialComponent', selectedTesimonialComponent);
//   // }, [selectedTesimonialComponent]);


//   useEffect(() => {
//     localStorage.setItem('selectedContactComponent', selectedContactComponent);
//   }, [selectedContactComponent]);


//   useEffect(() => {
//     localStorage.setItem('selectedFooterComponent', selectedFooterComponent);
//   }, [selectedFooterComponent]);



  

//   const handleComponentSelect = (componentName) => {
//     setSelectedComponent(componentName);
//     setShowHeadersModal(false);
//   };

//   const handleAboutComponentSelect = (componentName) => {
//     setSelectedAboutComponent(componentName);
//     setShowAboutPagesModal(false);
//   };

//   const handleTabComponentSelect = (componentName) => {
//     setSelectedTabComponent(componentName);
//     setShowTabPageModel(false);
//   };

//   const handleBannerComponentSelect = (componentName) => {
//     setSelectedBannerComponent(componentName);
//     setShowBannerPageModel(false);
//   };

//   // const handleTesimonialComponentSelect = (componentName) => {
//   //   setSelectedTesimonialComponent(componentName);
//   //   setShowTesimonialPageModel(false);
//   // };

//   const handleContactComponentSelect = (componentName) => {
//     setSelectedContactComponent(componentName);
//     setShowContactPageModel(false);
//   };


//   const handleFooterComponentSelect = (componentName) => {
//     setSelectedFooterComponent(componentName);
//     setShowFooterPageModel(false);
//   };




  
//   const headerComponents = [
//     { name: 'Header', component: <Header handleSelect={() => handleComponentSelect('Header')} /> },
//     { name: 'Header2', component: <Header2 handleSelect={() => handleComponentSelect('Header2')} /> },
//     { name: 'Header3', component: <Header3 handleSelect={() => handleComponentSelect('Header3')} /> },
//     { name: 'Header4', component: <Header4 handleSelect={() => handleComponentSelect('Header4')} /> },
//     { name: 'Header5', component: <Header5 handleSelect={() => handleComponentSelect('Header5')} /> },
//   ];

//   const aboutPageComponents = [
//     { name: 'AboutPage1', component: <AboutPage1 handleSelect={() => handleAboutComponentSelect('AboutPage1')} /> },
//     { name: 'AboutPage2', component: <AboutPage2 handleSelect={() => handleAboutComponentSelect('AboutPage2')} /> },
//     { name: 'AboutPage3', component: <AboutPage3 handleSelect={() => handleAboutComponentSelect('AboutPage3')} /> },
//     { name: 'AboutPage4', component: <AboutPage4 handleSelect={() => handleAboutComponentSelect('AboutPage4')} /> },
//     { name: 'AboutPage5', component: <AboutPage5 handleSelect={() => handleAboutComponentSelect('AboutPage5')} /> },
//   ];

//   const tabPageComponents = [
//     { name: 'TabBar1', component: <TabBar1 handleSelect={() => handleTabComponentSelect('TabBar1')} /> },
//     { name: 'TabBar2', component: <TabBar2 handleSelect={() => handleTabComponentSelect('TabBar2')} /> },
//     { name: 'TabBar3', component: <TabBar3 handleSelect={() => handleTabComponentSelect('TabBar3')} /> },
//     { name: 'TabBar4', component: <TabBar4 handleSelect={() => handleTabComponentSelect('TabBar4')} /> },
//     { name: 'TabBar5', component: <TabBar5 handleSelect={() => handleTabComponentSelect('TabBar5')} /> },
//   ];

//   const bannerComponents = [
//     { name: 'Banner1', component: <Banner1 handleSelect={() => handleBannerComponentSelect('Banner1')} /> },
//     { name: 'Banner2', component: <Banner2 handleSelect={() => handleBannerComponentSelect('Banner2')} /> },
//     { name: 'Banner3', component: <Banner3 handleSelect={() => handleBannerComponentSelect('Banner3')} /> },
//     { name: 'Banner4', component: <Banner4 handleSelect={() => handleBannerComponentSelect('Banner4')} /> },
//     { name: 'Banner5', component: <Banner5 handleSelect={() => handleBannerComponentSelect('Banner5')} /> },
//   ];

//   // const tesimonialComponents = [
//   //   { name: 'Tesimonial1', component: <Tesimonial1 handleSelect={() => handleTesimonialComponentSelect('Tesimonial1')} /> },
//   //   { name: 'Tesimonial2', component: <Tesimonial2 handleSelect={() => handleTesimonialComponentSelect('Tesimonial2')} /> },
//   //   { name: 'Tesimonial3', component: <Tesimonial3 handleSelect={() => handleTesimonialComponentSelect('Tesimonial3')} /> },
//   //   { name: 'Tesimonial4', component: <Tesimonial4 handleSelect={() => handleTesimonialComponentSelect('Tesimonial4')} /> },
//   //   { name: 'Tesimonial5', component: <Tesimonial5 handleSelect={() => handleTesimonialComponentSelect('Tesimonial5')} /> },
//   // ];



//   const ContactComponents = [
//     { name: 'Contact1', component: <Contact1 handleSelect={() => handleContactComponentSelect('Contact1')} /> },
//     { name: 'Contact2', component: <Contact2 handleSelect={() => handleContactComponentSelect('Contact2')} /> },
//     { name: 'Contact3', component: <Contact3 handleSelect={() => handleContactComponentSelect('Contact3')} /> },
//     { name: 'Contact4', component: <Contact4 handleSelect={() => handleContactComponentSelect('Contact4')} /> },
//     { name: 'Contact5', component: <Contact5 handleSelect={() => handleContactComponentSelect('Contact5')} /> },
//   ];

//   const FooterComponents = [
//     { name: 'Footer1', component: <Footer1 handleSelect={() => handleFooterComponentSelect('Footer1')} /> },
//     { name: 'Footer2', component: <Footer2 handleSelect={() => handleFooterComponentSelect('Footer2')} /> },
//     { name: 'Footer3', component: <Footer3 handleSelect={() => handleFooterComponentSelect('Footer3')} /> },
//     { name: 'Footer4', component: <Footer4 handleSelect={() => handleFooterComponentSelect('Footer4')} /> },
//     { name: 'Footer5', component: <Footer5 handleSelect={() => handleFooterComponentSelect('Footer5')} /> },
//   ];

  

  
//   return (
//     <div className="app-container">
//       <div className="component-display">
//         {headerComponents.find(c => c.name === selectedComponent)?.component}
//       </div>
      
//       <div className="all_seleted_btn">
//         <Button 
//           className={`mainShowbtn btn_i ${selectedComponent === 'Header' ? 'active' : ''}`} 
//           onClick={() => setShowHeadersModal(true)}
//         >
//           Show All Headers
//         </Button>
//         <br />
//         <Button 
//           className={`mainShowbtn btn_i ${selectedAboutComponent === 'AboutPage1' ? 'active' : ''}`} 
//           onClick={() => setShowAboutPagesModal(true)}
//         >
//           Show All About Pages
//         </Button>
//         <br />
//         <Button 
//           className={`mainShowbtn btn_i ${selectedTabComponent === 'TabBar1' ? 'active' : ''}`} 
//           onClick={() => setShowTabPageModel(true)}
//         >
//           Show All Tab Pages
//         </Button>
//         <br />
//         <Button 
//           className={`mainShowbtn btn_i ${selectedBannerComponent === 'Banner1' ? 'active' : ''}`} 
//           onClick={() => setShowBannerPageModel(true)}
//         >
//           Show All Banner Pages
//         </Button>
//         <br />
//         {/* <Button 
//           className={`mainShowbtn btn_i ${selectedTesimonialComponent === 'Tesimonial1' ? 'active' : ''}`} 
//           onClick={() => setShowTesimonialPageModel(true)}
//         >
//           Show All Testimonials
//         </Button> */}
//         <Button 
//           className={`mainShowbtn btn_i ${selectedContactComponent === 'Contact1' ? 'active' : ''}`} 
//           onClick={() => setShowContactPageModel(true)}
//         >
//           Show All Contact
//         </Button>

//         <Button 
//           className={`mainShowbtn btn_i ${selectedFooterComponent === 'Footer1' ? 'active' : ''}`} 
//           onClick={() => setShowFooterPageModel(true)}
//         >
//           Show All Footer
//         </Button>
//       </div>



      

//       <div className="component-display">
//         {aboutPageComponents.find(c => c.name === selectedAboutComponent)?.component}
//       </div>

//       <div className="component-display">
//         {tabPageComponents.find(c => c.name === selectedTabComponent)?.component}
//       </div>

//       <div className="component-display">
//         {bannerComponents.find(c => c.name === selectedBannerComponent)?.component}
//       </div>

//       {/* <div className="component-display">
//         {tesimonialComponents.find(c => c.name === selectedTesimonialComponent)?.component}
//       </div> */}

//       <Modal show={showHeadersModal} onHide={() => setShowHeadersModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Select a Header</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {headerComponents.map(c => (
//             <div 
//               key={c.name} 
//               onClick={() => handleComponentSelect(c.name)}
//               className={selectedComponent === c.name ? 'active' : ''}
//             >
//               {c.component}
//             </div>
//           ))}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showAboutPagesModal} onHide={() => setShowAboutPagesModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Select an About Page</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {aboutPageComponents.map(c => (
//             <div 
//               key={c.name} 
//               onClick={() => handleAboutComponentSelect(c.name)}
//               className={selectedAboutComponent === c.name ? 'active' : ''}
//             >
//               {c.component}
//             </div>
//           ))}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showTabPageModel} onHide={() => setShowTabPageModel(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Select a Tab Page</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {tabPageComponents.map(c => (
//             <div 
//               key={c.name} 
//               onClick={() => handleTabComponentSelect(c.name)}
//               className={selectedTabComponent === c.name ? 'active' : ''}
//             >
//               {c.component}
//             </div>
//           ))}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showBannerPageModel} onHide={() => setShowBannerPageModel(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Select a Banner Page</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {bannerComponents.map(c => (
//             <div 
//               key={c.name} 
//               onClick={() => handleBannerComponentSelect(c.name)}
//               className={selectedBannerComponent === c.name ? 'active' : ''}
//             >
//               {c.component}
//             </div>
//           ))}
//         </Modal.Body>
//       </Modal> 

   
//       <Modal show={showContactPageModel} onHide={() => setShowContactPageModel(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Select a Contact </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {ContactComponents.map(c => (
//             <div 
//               key={c.name} 
//               onClick={() => handleContactComponentSelect(c.name)}
//               className={selectedContactComponent === c.name ? 'active' : ''}
//             >
//               {c.component}
//             </div>
//           ))}
//         </Modal.Body>
//       </Modal>
//       <Modal show={showFooterPageModel} onHide={() => setShowFooterPageModel(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Select a Testimonial</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {FooterComponents.map(c => (
//             <div 
//               key={c.name} 
//               onClick={() => handleFooterComponentSelect(c.name)}
//               className={selectedFooterComponent === c.name ? 'active' : ''}
//             >
//               {c.component}
//             </div>
//           ))}
//         </Modal.Body>
//       </Modal>



      
//     </div>



//   );
// }

// export default App;







import './App.css';
import '../src/Main.css';
import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Header page imports
import Header from './components1/Header';
import Header2 from './components2/Header2';
import Header3 from './components3/Header3';
import Header4 from './components4/Header4';
import Header5 from './components5/Header5';

// About Page imports
import AboutPage1 from './components1/AboutPage1';
import AboutPage2 from './components2/AboutPage2';
import AboutPage3 from './components3/AboutPage3';
import AboutPage4 from './components4/AboutPage4';
import AboutPage5 from './components5/AboutPage5';

// Tab Bar imports
import TabBar1 from './components1/TabBar1';
import TabBar2 from './components2/TabBar2';
import TabBar3 from './components3/TabBar3';
import TabBar4 from './components4/TabBar4';
import TabBar5 from './components5/TabBar5';

// Banner imports
import Banner1 from './components1/Banner1';
import Banner2 from './components2/Banner2';
import Banner3 from './components3/Banner3';
import Banner4 from './components4/Banner4';
import Banner5 from './components5/Banner5';

// Contact Page imports
import Contact1 from './components1/Contact1';
import Contact2 from './components2/Contact2';
import Contact3 from './components3/Contact3';
import Contact4 from './components4/Contact4';
import Contact5 from './components5/Contact5';

// Footer Page imports
import Footer1 from './components1/Footer1';
import Footer2 from './components2/Footer2';
import Footer3 from './components3/Footer3';
import Footer4 from './components4/Footer4';
import Footer5 from './components5/Footer5';

function App() {
  const [selectedComponent, setSelectedComponent] = useState(localStorage.getItem('selectedComponent') || 'Header');
  const [selectedAboutComponent, setSelectedAboutComponent] = useState(localStorage.getItem('selectedAboutComponent') || 'AboutPage1');
  const [selectedTabComponent, setSelectedTabComponent] = useState(localStorage.getItem('selectedTabComponent') || 'TabBar1');
  const [selectedBannerComponent, setSelectedBannerComponent] = useState(localStorage.getItem('selectedBannerComponent') || 'Banner1');
  const [selectedContactComponent, setSelectedContactComponent] = useState(localStorage.getItem('selectedContactComponent') || 'Contact1');
  const [selectedFooterComponent, setSelectedFooterComponent] = useState(localStorage.getItem('selectedFooterComponent') || 'Footer1');
  
  const [showHeadersModal, setShowHeadersModal] = useState(false);
  const [showAboutPagesModal, setShowAboutPagesModal] = useState(false);
  const [showTabPageModel, setShowTabPageModel] = useState(false);
  const [showBannerPageModel, setShowBannerPageModel] = useState(false);
  const [showContactPageModel, setShowContactPageModel] = useState(false);
  const [showFooterPageModel, setShowFooterPageModel] = useState(false);

  useEffect(() => {
    localStorage.setItem('selectedComponent', selectedComponent);
  }, [selectedComponent]);

  useEffect(() => {
    localStorage.setItem('selectedAboutComponent', selectedAboutComponent);
  }, [selectedAboutComponent]);

  useEffect(() => {
    localStorage.setItem('selectedTabComponent', selectedTabComponent);
  }, [selectedTabComponent]);

  useEffect(() => {
    localStorage.setItem('selectedBannerComponent', selectedBannerComponent);
  }, [selectedBannerComponent]);

  useEffect(() => {
    localStorage.setItem('selectedContactComponent', selectedContactComponent);
  }, [selectedContactComponent]);

  useEffect(() => {
    localStorage.setItem('selectedFooterComponent', selectedFooterComponent);
  }, [selectedFooterComponent]);

  const handleComponentSelect = (componentName) => {
    setSelectedComponent(componentName);
    setShowHeadersModal(false);
  };

  const handleAboutComponentSelect = (componentName) => {
    setSelectedAboutComponent(componentName);
    setShowAboutPagesModal(false);
  };

  const handleTabComponentSelect = (componentName) => {
    setSelectedTabComponent(componentName);
    setShowTabPageModel(false);
  };

  const handleBannerComponentSelect = (componentName) => {
    setSelectedBannerComponent(componentName);
    setShowBannerPageModel(false);
  };

  const handleContactComponentSelect = (componentName) => {
    setSelectedContactComponent(componentName);
    setShowContactPageModel(false);
  };

  const handleFooterComponentSelect = (componentName) => {
    setSelectedFooterComponent(componentName);
    setShowFooterPageModel(false);
  };

  const headerComponents = [
    { name: 'Header', component: <Header handleSelect={() => handleComponentSelect('Header')} /> },
    { name: 'Header2', component: <Header2 handleSelect={() => handleComponentSelect('Header2')} /> },
    { name: 'Header3', component: <Header3 handleSelect={() => handleComponentSelect('Header3')} /> },
    { name: 'Header4', component: <Header4 handleSelect={() => handleComponentSelect('Header4')} /> },
    { name: 'Header5', component: <Header5 handleSelect={() => handleComponentSelect('Header5')} /> },
  ];

  const aboutPageComponents = [
    { name: 'AboutPage1', component: <AboutPage1 handleSelect={() => handleAboutComponentSelect('AboutPage1')} /> },
    { name: 'AboutPage2', component: <AboutPage2 handleSelect={() => handleAboutComponentSelect('AboutPage2')} /> },
    { name: 'AboutPage3', component: <AboutPage3 handleSelect={() => handleAboutComponentSelect('AboutPage3')} /> },
    { name: 'AboutPage4', component: <AboutPage4 handleSelect={() => handleAboutComponentSelect('AboutPage4')} /> },
    { name: 'AboutPage5', component: <AboutPage5 handleSelect={() => handleAboutComponentSelect('AboutPage5')} /> },
  ];

  const tabPageComponents = [
    { name: 'TabBar1', component: <TabBar1 handleSelect={() => handleTabComponentSelect('TabBar1')} /> },
    { name: 'TabBar2', component: <TabBar2 handleSelect={() => handleTabComponentSelect('TabBar2')} /> },
    { name: 'TabBar3', component: <TabBar3 handleSelect={() => handleTabComponentSelect('TabBar3')} /> },
    { name: 'TabBar4', component: <TabBar4 handleSelect={() => handleTabComponentSelect('TabBar4')} /> },
    { name: 'TabBar5', component: <TabBar5 handleSelect={() => handleTabComponentSelect('TabBar5')} /> },
  ];

  const bannerComponents = [
    { name: 'Banner1', component: <Banner1 handleSelect={() => handleBannerComponentSelect('Banner1')} /> },
    { name: 'Banner2', component: <Banner2 handleSelect={() => handleBannerComponentSelect('Banner2')} /> },
    { name: 'Banner3', component: <Banner3 handleSelect={() => handleBannerComponentSelect('Banner3')} /> },
    { name: 'Banner4', component: <Banner4 handleSelect={() => handleBannerComponentSelect('Banner4')} /> },
    { name: 'Banner5', component: <Banner5 handleSelect={() => handleBannerComponentSelect('Banner5')} /> },
  ];

  const ContactComponents = [
    { name: 'Contact1', component: <Contact1 handleSelect={() => handleContactComponentSelect('Contact1')} /> },
    { name: 'Contact2', component: <Contact2 handleSelect={() => handleContactComponentSelect('Contact2')} /> },
    { name: 'Contact3', component: <Contact3 handleSelect={() => handleContactComponentSelect('Contact3')} /> },
    { name: 'Contact4', component: <Contact4 handleSelect={() => handleContactComponentSelect('Contact4')} /> },
    { name: 'Contact5', component: <Contact5 handleSelect={() => handleContactComponentSelect('Contact5')} /> },
  ];

  const FooterComponents = [
    { name: 'Footer1', component: <Footer1 handleSelect={() => handleFooterComponentSelect('Footer1')} /> },
    { name: 'Footer2', component: <Footer2 handleSelect={() => handleFooterComponentSelect('Footer2')} /> },
    { name: 'Footer3', component: <Footer3 handleSelect={() => handleFooterComponentSelect('Footer3')} /> },
    { name: 'Footer4', component: <Footer4 handleSelect={() => handleFooterComponentSelect('Footer4')} /> },
    { name: 'Footer5', component: <Footer5 handleSelect={() => handleFooterComponentSelect('Footer5')} /> },
  ];

  return (
    <div className="app-container">
      <div className="component-display">
      <Button className='mainShowheaderbutton btn_i' onClick={() => setShowHeadersModal(true)}>Select Header</Button>

        {headerComponents.find(c => c.name === selectedComponent)?.component}
       
      </div>
      
      <div className="component-display">
 
      <Button className='mainShowbutton btn_i' onClick={() => setShowAboutPagesModal(true)}>Select About </Button>
        {aboutPageComponents.find(c => c.name === selectedAboutComponent)?.component}
      
      </div>
      
      <div className="component-display">
      <Button className='mainShowbutton btn_i' onClick={() => setShowTabPageModel(true)}>Select Tab</Button>
        {tabPageComponents.find(c => c.name === selectedTabComponent)?.component}
        
      </div>
      
      <div className="component-display">
      <Button className='mainShowbutton btn_i' onClick={() => setShowBannerPageModel(true)}>Select Banner</Button>
        {bannerComponents.find(c => c.name === selectedBannerComponent)?.component}
        
      </div>

      <div className="component-display">
      <Button className='mainShowbutton btn_i' onClick={() => setShowContactPageModel(true)}>Select Contact</Button>
        {ContactComponents.find(c => c.name === selectedContactComponent)?.component}
       
      </div>

      <div className="component-display">
      <Button className='mainShowbutton btn_i' onClick={() => setShowFooterPageModel(true)}>Select Footer</Button>
        {FooterComponents.find(c => c.name === selectedFooterComponent)?.component}
        
      </div>
 {/* Modals */}

 <Modal show={showHeadersModal} onHide={() => setShowHeadersModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Select a Header</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {headerComponents.map(c => (
      <div 
        key={c.name} 
        className={`component-container ${selectedComponent === c.name ? 'active' : ''}`}
        style={{ padding: '10px', border: '1px solid #ccc', margin: '5px 0' }} // Optional styles
      >
        <p>{c.component}</p>
        <button className='mainSelectbtn btn' onClick={() => handleComponentSelect(c.name)}>
          Select
        </button>
      </div>
    ))}
  </Modal.Body>
</Modal>



      <Modal show={showAboutPagesModal} onHide={() => setShowAboutPagesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select an About Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {aboutPageComponents.map(c => (
            <div 
              key={c.name} 
              onClick={() => handleAboutComponentSelect(c.name)}
              className={selectedAboutComponent === c.name ? 'active' : ''}
            >
              <p>{c.component}</p>
              <button className='mainSelectbtn btn' onClick={() => handleAboutComponentSelect(c.name)}>
              Select
              </button>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <Modal show={showTabPageModel} onHide={() => setShowTabPageModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Tab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tabPageComponents.map(c => (
            <div 
              key={c.name} 
              onClick={() => handleTabComponentSelect(c.name)}
              className={selectedTabComponent === c.name ? 'active' : ''}
            >
              <p>{c.component}</p>
              <button className='mainSelectbtn btn' onClick={() => handleTabComponentSelect(c.name)}>
              Select
              </button>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <Modal show={showBannerPageModel} onHide={() => setShowBannerPageModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bannerComponents.map(c => (
            <div 
              key={c.name} 
              onClick={() => handleBannerComponentSelect(c.name)}
              className={selectedBannerComponent === c.name ? 'active' : ''}
            >
              <p>{c.component}</p>
              <button className='mainSelectbtn btn' onClick={() => handleBannerComponentSelect(c.name)}>
              Select
              </button>
              
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <Modal show={showContactPageModel} onHide={() => setShowContactPageModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ContactComponents.map(c => (
            <div 
              key={c.name} 
              onClick={() => handleContactComponentSelect(c.name)}
              className={selectedContactComponent === c.name ? 'active' : ''}
            >
              <p>{c.component}</p>
              <button className='mainSelectbtn btn' onClick={() => handleContactComponentSelect(c.name)}>
              Select
              </button>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <Modal show={showFooterPageModel} onHide={() => setShowFooterPageModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Footer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {FooterComponents.map(c => (
            <div 
              key={c.name} 
              onClick={() => handleFooterComponentSelect(c.name)}
              className={selectedFooterComponent === c.name ? 'active' : ''}
            >
              <p>{c.component}</p>
              <button className='mainSelectbtn btn' onClick={() => handleFooterComponentSelect(c.name)}>
              Select
              </button>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;



